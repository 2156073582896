import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import defaultLocations from '../../default-location-searches';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate } from '../../util/dates';
import { richText } from '../../util/richText';
import { boundsToLocation, userLocation } from '../../util/maps';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  ClaimModal,
  Page,
  IconSpinner,
  Modal,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LandingFooter,
  BookingPanel,
  PrimaryButton,
  SecondaryButton,
  NamedLink,
  ReportIssueModal,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import {
  sendEnquiry,
  fetchTimeSlots,
  fetchTransactionLineItems,
  fetchListingReviews,
} from './ListingPage.duck';
import { requestCreateListingDraft } from '../EditListingPage/EditListingPage.duck';
import { queryPreviousListings, claimItem, closeClaimModal } from '../WantedPage/WantedPage.duck';
import SectionHeading from './SectionHeading';
import SectionHighlightsMaybe from './SectionHighlightsMaybe';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionImages from './SectionImages';
import SectionMapMaybe from './SectionMapMaybe';
import { getDistance } from 'geolib';
import SectionLenderInfo from './SectionLenderInfo';
import { DEFAULT_TITLE, LISTING_TYPE } from '../../constants';
import { messageProvider } from '../WantedPage/WantedPage.duck';
import SectionReviews from '../../components/SectionReviews/SectionReviews';

import css from './ListingPage.module.css';

const seattleCenter = boundsToLocation(
  defaultLocations.find(l => l.id === 'default-seattle').predictionPlace.bounds
);
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, LatLng } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      userLatLng: null,
      latLngLoading: false,
      claimedModalOpen: false,
      messageModalOpen: false,
      messageSent: false,
      submitInProgress: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  componentDidMount() {
    const getUserLocation = async () => {
      try {
        this.setState({ latLngLoading: true });
        const location = await userLocation();
        this.setState({ userLatLng: location });
      } catch {
      } finally {
        this.setState({ latLngLoading: false });
      }
    };
    getUserLocation();
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, quantity, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      ...restOfValues,
      quantity,
      seats: quantity,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      previousListings,
      previousListingsPagination,
      queryPreviousListingsInProgress,
      queryPreviousListingsError,
      onFetchPreviousListings,
      onClaimItem,
      onCloseClaimModal,
      claimInProgress,
      claimSent,
      claimError,
      onCreateNewListing,
      messageProviderError,
      onMessageProvider,
      // Reviews
      reviews,
      reviewsPagination,
      queryReviewsError,
      queryReviewsInProgress,
      onFetchListingReviews,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const isWantedPost =
      currentListing?.attributes?.publicData?.listingType === LISTING_TYPE.WANTED_POST;

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      price = null,
      title = '',
      publicData,
      metadata,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <LandingFooter isAuthenticated={isAuthenticated} />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <LandingFooter isAuthenticated={isAuthenticated} />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const currentUserId = currentUser?.id?.uuid;
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUserId;

    const { deliveryMethod: deliveryMethods, accessoriesIncluded } = publicData ? publicData : {};

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const displayName = ensuredAuthor.attributes?.profile?.publicData
      ? currentAuthor.attributes?.profile?.publicData?.accountType === 'Business'
        ? currentAuthor.attributes?.profile?.publicData?.businessName
        : currentAuthor.attributes?.profile?.displayName
      : null;
    const { publicData: authorPublicData } = ensuredAuthor.attributes.profile
      ? ensuredAuthor.attributes.profile
      : {};
    const { accountType } = authorPublicData ? authorPublicData : {};
    const { averageRating, ratingCount } = metadata || {};
    const isBusinessLender = accountType && accountType === 'Business';

    const isVerified = authorPublicData?.facebookConnected || false;

    const listingaddress = currentListing?.attributes?.publicData?.location?.address;
    const lenderAddressLabel = intl.formatMessage({ id: 'ListingPage.lenderAddress' });
    const listingLocation = currentListing.attributes.geolocation
      ? currentListing.attributes.geolocation
      : null;
    const geolocation = isBusinessLender
      ? listingLocation
      : listingLocation
      ? new LatLng(listingLocation.lat, listingLocation.lng)
      : null;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const center = this.state.userLatLng
      ? this.state.userLatLng
      : seattleCenter
      ? new LatLng(seattleCenter.lat, seattleCenter.lng)
      : null;
    const distance =
      center && geolocation
        ? (
            getDistance(
              {
                latitude: center.lat.toString(),
                longitude: center.lng.toString(),
              },
              {
                latitude: geolocation.lat.toString(),
                longitude: geolocation.lng.toString(),
              }
            ) * 0.000621371
          ) // transform kilometers to miles
            .toFixed(1)
        : null;

    const locationMsg = intl.formatMessage({ id: 'ListingPage.locationTitle' });

    const locationLabel = this.state.latLngLoading ? (
      <FormattedMessage id="ListingPage.distance" values={{ distance: <IconSpinner /> }} />
    ) : distance > 1 ? (
      <FormattedMessage id="ListingPage.distance" values={{ distance }} />
    ) : (
      <FormattedMessage id="ListingPage.lessThenMileAway" />
    );

    const wantedlabel = this.state.latLngLoading ? (
      <FormattedMessage id="ListingPage.wanteddistance" values={{ distance: <IconSpinner /> }} />
    ) : distance > 1 ? (
      <FormattedMessage id="ListingPage.wanteddistance" values={{ distance }} />
    ) : (
      <FormattedMessage id="ListingPage.lessThenMileAway" />
    );

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <SectionHeading
                    richTitle={richTitle}
                    authorName={displayName}
                    averageRating={averageRating}
                    numberOfReviews={ratingCount}
                    isVerified={isVerified}
                    isWantedPost={isWantedPost}
                  />

                  <SectionHighlightsMaybe
                    deliveryMethods={deliveryMethods}
                    accessoriesIncluded={accessoriesIncluded}
                  />

                  <SectionDescriptionMaybe description={description} />

                  <SectionImages
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                    isWantedPost={isWantedPost}
                  />
                  <div>
                    <h2 className={css.segmentTitle}>{locationMsg}</h2>
                    <div className={css.mapHeader}>
                      {this.state.latLngLoading ? <IconSpinner /> : null}
                      {isBusinessLender ? (
                        <div>
                          <p className={css.mapDescription}>{lenderAddressLabel}</p>
                          <p></p>
                          <p className={css.lenderAddress}>{listingaddress}</p>
                        </div>
                      ) : isWantedPost ? (
                        <p className={css.mapDescription}>{wantedlabel}</p>
                      ) : (
                        <p className={css.mapDescription}>{locationLabel}</p>
                      )}
                    </div>

                    <SectionMapMaybe
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing?.id?.uuid}
                      showTitle={false}
                      isStatic
                    />
                  </div>

                  {!isWantedPost && (
                    <SectionReviews
                      reviews={reviews}
                      pagination={reviewsPagination}
                      inProgress={queryReviewsInProgress}
                      error={queryReviewsError}
                      onFetchMore={newPage => onFetchListingReviews(listingId, newPage)}
                      showLineBreak
                      isListing
                    />
                  )}

                  <SectionLenderInfo
                    displayName={displayName}
                    isVerified={isVerified}
                    author={ensuredAuthor}
                    isWantedPost={isWantedPost}
                  />
                  <div className={css.contactSpan}>
                    <SecondaryButton
                      className={css.claimButton}
                      disabled={!currentUserId || isOwnListing}
                    >
                      <NamedLink
                        className={css.profileLink}
                        name="ProfilePage"
                        params={{ id: ensuredAuthor.id.uuid }}
                      >
                        <FormattedMessage id="ListingPage.viewLender" />
                      </NamedLink>
                    </SecondaryButton>
                    <SecondaryButton
                      className={css.messageButton}
                      disabled={!currentUserId || isOwnListing}
                      onClick={() => this.setState({ messageModalOpen: true })}
                    >
                      <FormattedMessage id="ListingPage.messageLender" />
                    </SecondaryButton>
                  </div>
                </div>
                {isWantedPost ? (
                  <div className={css.claimContainer}>
                    <PrimaryButton
                      className={css.claimButton}
                      onClick={() => this.setState({ claimedModalOpen: true })}
                      disabled={!currentUserId || isOwnListing}
                    >
                      <FormattedMessage id="ListingPage.claim" />
                    </PrimaryButton>
                    <ClaimModal
                      id="ListingPage.ClaimModal"
                      isOpen={!!this.state.claimedModalOpen}
                      onCloseModal={() => {
                        this.setState({ claimedModalOpen: false });
                        onCloseClaimModal();
                      }}
                      claimedItemId={listingId}
                      onFetchPreviousListings={onFetchPreviousListings}
                      previousListings={previousListings}
                      previousListingsPagination={previousListingsPagination}
                      queryPreviousListingsInProgress={queryPreviousListingsInProgress}
                      queryPreviousListingsError={queryPreviousListingsError}
                      onClaimItem={(claimedItemId, listingId, messageContent) =>
                        onClaimItem(claimedItemId.uuid, listingId, currentUserId, messageContent)
                      }
                      claimInProgress={claimInProgress}
                      claimSent={claimSent}
                      claimError={claimError}
                      onCreateNewListing={onCreateNewListing}
                      onManageDisableScrolling={onManageDisableScrolling}
                    />
                  </div>
                ) : (
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    title={bookingTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    isOwnListing={isOwnListing}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                  />
                )}
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
            <ReportIssueModal
              id="MessageWanted"
              onManageDisableScrolling={onManageDisableScrolling}
              isOpen={this.state.messageModalOpen}
              onCloseModal={() => {
                this.setState({ messageModalOpen: false });
              }}
              displayName={displayName}
              listingTitle={title}
              author={ensuredAuthor}
              onSubmit={({ text }) => {
                this.setState({ submitInProgress: true });
                onMessageProvider(listingId.uuid, currentUserId, text);
                this.setState({ messageSent: true });
                this.setState({ submitInProgress: false });
              }}
              submitInProgress={this.state.submitInProgress}
              ready={this.state.messageSent}
              submitError={messageProviderError}
              headerText={intl.formatMessage({ id: 'WantedTable.sendMessageTitle' })}
              inputPlaceholder={intl.formatMessage({ id: 'WantedTable.sendMessagePlaceholder' })}
            />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <LandingFooter isAuthenticated={isAuthenticated} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  isAuthenticated: false,
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    // Reviews
    reviews,
    reviewsPagination,
    queryReviewsError,
    queryReviewsInProgress,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const {
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    claimInProgress,
    claimSent,
    claimError,
  } = state.WantedPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);

    if (listings.length === 1) {
      const { attributes, ...restOfListing } = listings[0];

      const { title: listingTitle, ...restOfAttr } = attributes;

      const title = listingTitle === DEFAULT_TITLE ? 'No title' : listingTitle;

      return { ...restOfListing, attributes: { ...restOfAttr, title } };
    }

    return null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    previousListings,
    previousListingsPagination,
    queryPreviousListingsInProgress,
    queryPreviousListingsError,
    claimInProgress,
    claimSent,
    claimError,
    // Reviews
    reviews,
    reviewsPagination,
    queryReviewsError,
    queryReviewsInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onMessageProvider: (listingId, userId, text) =>
    dispatch(messageProvider(listingId, userId, text)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end) => dispatch(fetchTimeSlots(listingId, start, end)),
  onFetchPreviousListings: page => dispatch(queryPreviousListings({ page })),
  onClaimItem: (claimedItemId, listingId, userId, messageContent) =>
    dispatch(claimItem(claimedItemId, listingId, userId, messageContent)),
  onCloseClaimModal: () => dispatch(closeClaimModal()),
  onCreateNewListing: data => dispatch(requestCreateListingDraft(data)),
  onFetchListingReviews: (listingId, page) => dispatch(fetchListingReviews(listingId, page)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
