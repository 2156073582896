import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    isWantedPost,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;

  return (
    <div className={css.sectionImages}>
      <h3 className={css.segmentTitle}>
        <FormattedMessage id="ListingPage.photos" />
      </h3>
      {hasImages ? (
        <React.Fragment>
          <div className={css.images}>
            {listing.images.map(image => (
              <div key={image.id.uuid} className={css.threeToTwoWrapper}>
                <div className={css.aspectWrapper}>
                  <ResponsiveImage
                    onClick={handleViewPhotosClick}
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={image}
                    variants={[
                      'landscape-crop',
                      'landscape-crop2x',
                      'landscape-crop4x',
                      'landscape-crop6x',
                    ]}
                  />
                </div>
              </div>
            ))}
          </div>
          <hr className={css.linebreak}></hr>
        </React.Fragment>
      ) : isWantedPost ? (
        <FormattedMessage id="ListingPage.noWantedImages" />
      ) : (
        <FormattedMessage id="ListingPage.noLenderImages" />
      )}
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
