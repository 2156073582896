import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ReviewRating } from '../../components';
// import VerifiedBadge, { BADGE_SIZE_SMALL } from '../../components/VerifiedBadge/VerifiedBadge';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    richTitle,
    authorName,
    averageRating,
    numberOfReviews,
    // eslint-disable-next-line no-unused-vars
    // isVerified,
    isWantedPost,
  } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        {isWantedPost && (
          <h1 className={css.wantedTitle}>
            <FormattedMessage id="ListingPage.wantedTitle" />
          </h1>
        )}
        <h1 className={css.title}>{richTitle}</h1>
        <h2 className={css.author}>
          {authorName}
          {/* {isVerified && (
            <VerifiedBadge
              className={css.verifiedBadge}
              rootClassName={css.verifiedBadgeRoot}
              isVerified={isVerified}
              size={BADGE_SIZE_SMALL}
              onlyCheckmark
            />
          )} */}
        </h2>
        {!isWantedPost && (
          <div className={css.ratingContainer}>
            <ReviewRating rating={averageRating} />
            <h3 className={css.reviews}>
              <FormattedMessage
                id="ListingPage.numberOfReviews"
                values={{ numReviews: numberOfReviews ? numberOfReviews : 0 }}
              />
            </h3>
          </div>
        )}
      </div>
      <hr className={css.linebreak}></hr>
    </div>
  );
};

export default SectionHeading;
