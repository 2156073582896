import React, { useMemo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconAdd, IconBusiness } from '../../components';
import { IconCar } from '../../components';
import { DELIVERY_METHODS } from '../../constants';

import css from './ListingPage.module.css';

const getAvailableMethods = deliveryMethods => {
  if (!deliveryMethods || deliveryMethods.length < 1) return null;
  const methods = Array.isArray(deliveryMethods) ? deliveryMethods : [deliveryMethods];

  return methods.map(method =>
    method === DELIVERY_METHODS.PICK_UP
      ? {
          key: DELIVERY_METHODS.PICK_UP,
          titleKey: 'ListingPage.highlightPickupTitle',
          descKey: 'ListingPage.highlightPickupDescription',
        }
      : {
          key: DELIVERY_METHODS.ON_SITE_RENTAL,
          titleKey: 'ListingPage.highlightOnSiteTitle',
          descKey: 'ListingPage.highlightOnSiteDescription',
        }
  );
};

const SectionHighlightsMaybe = props => {
  const { deliveryMethods, accessoriesIncluded } = props;

  const availableMethods = useMemo(() => getAvailableMethods(deliveryMethods), [deliveryMethods]);

  return availableMethods ? (
    <>
      <h3 className={css.segmentTitle}>
        <FormattedMessage id="ListingPage.itemHightlights" />
      </h3>
      {availableMethods.map(method => (
        <div className={css.highlight} key={method.key}>
          {method.key === 'pickUp' ? (
            <IconCar rootClassName={css.highlightIcon} />
          ) : (
            <IconBusiness rootClassName={css.highlightIcon} />
          )}
          <div className={css.highlightDescription}>
            <span className={css.highlightDescriptionBold}>
              <FormattedMessage id={method.titleKey} />
            </span>
            <FormattedMessage id={method.descKey} />
          </div>
        </div>
      ))}
      {accessoriesIncluded && (
        <div className={css.highlight}>
          <IconAdd rootClassName={css.highlightIcon} />
          <div className={css.highlightDescription}>
            <span className={css.highlightDescriptionBold}>
              <FormattedMessage id="ListingPage.accessoriesIncluded" />
            </span>
            <FormattedMessage id="ListingPage.accessoriesIncludedDescription" />
          </div>
        </div>
      )}
      <hr className={css.linebreak}></hr>
    </>
  ) : null;
};

export default SectionHighlightsMaybe;
