import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { AvatarMedium, ReviewRating } from '../../components';
// import VerifiedBadge, { BADGE_SIZE_SMALL } from '../../components/VerifiedBadge/VerifiedBadge';

import css from './ListingPage.module.css';

const SectionLenderInfo = props => {
  const { author, /*isVerified,*/ displayName, isWantedPost } = props;
  const { bio, metadata } = author.attributes.profile;
  const { averageRating, ratingCount = 0 } = metadata ? metadata : {};

  return (
    <div className={css.sectionLenderInfo}>
      <h2 className={css.segmentTitle}>
        {isWantedPost ? (
          <FormattedMessage id="ListingPage.borrowerInfoHeading" />
        ) : (
          <FormattedMessage id="ListingPage.lenderInfoHeading" />
        )}
      </h2>

      <div className={css.author}>
        <AvatarMedium className={css.authorAvatar} user={author} disableProfileLink />
        <div className={css.authorContent}>
          <div className={css.authorInfo}>
            <div className={css.authorDescription}>
              <NamedLink
                className={css.userName}
                name="ProfilePage"
                params={{ id: author.id.uuid }}
              >
                {displayName}
                {/* <VerifiedBadge
                  className={css.verifiedBadge}
                  rootClassName={css.verifiedBadgeRoot}
                  isVerified={isVerified}
                  size={BADGE_SIZE_SMALL}
                  onlyCheckmark
                /> */}
              </NamedLink>
              <div className={css.userBio}>{bio}</div>
            </div>
            <div className={css.reviewContainer}>
              <ReviewRating
                rating={averageRating}
                className={css.desktopReviewRating}
                reviewStarClassName={css.reviewRatingStar}
              />
              <FormattedMessage id="ListingCard.ratingCount" values={{ count: ratingCount }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionLenderInfo;
